(function () {
    'use strict';

    /* eslint-disable no-invalid-this */
    ebates.stats = ebates.stats || {};
    ebates.stats.taggedModuleInit = scrollerEl => {
        /**
         *  Improved tagging functionality
         *  Supports "topic/collection/module - tile/item" structure
         *  topic/collection/module is identified via "data-is-topic" tag
         *  Tile/item "data-view-evt" tracking will be initialized only after its parent "data-view-evt" is fired
         *  assumption: View Tile event can't be fired before its parent View Topic/Module one
         *  Reuses unified data tagging schema used by click events - thus reducing tag bloating
         *  Work in progress - initTileViewTracking() needs to be implemented (for both horizontal scrolling and non scrolling modules)
         *  IMPORTANT - this should be refactored to use Intersection Observer, as soon as we retire IE 11
         */
        {
            const scrollThrottle = 500,
                modules = scrollerEl.querySelectorAll('[data-view-evt][data-is-topic]'),  // data-is-topic - to differentiate between tile and topic
                /**
                 * @description recursively (if needed) travel up the DOM tree to find the prop data attrs
                 * @param el
                 * @param prop
                 * @returns {*|string|null}
                 */
                getPropValue = (el, prop) => el.dataset[prop] || ((el.parentNode && el.parentNode !== document) ? getPropValue(el.parentNode, prop) : null),
                /**
                 * @description construct and return event object; may be used for different type of events, hence the type and sig params
                 * @param el
                 * @param eventType
                 * @param eventSignature
                 * @returns {*}
                 */
                getEventData = (el, eventType, eventSignature) => {
                    const propNames = eventSignature && eventSignature.split(','),
                        eventData = {
                            eventType,
                            eventProps: {}
                        };

                    return propNames ? propNames.reduce(
                        (data, fieldName) => {
                            switch (fieldName) {
                            // - translate '_eds' to the addEdsPropSet flag, which in turn will signal the SDK to add the EDS user data set to the event
                            case '_eds':
                                data.addEdsPropSet = true;
                                break;
                            case 'topic_position': {
                                // If there is no already provided value, topic_position will be calculated programmatically
                                const topicPositionVal = getPropValue(el, 'topic_position');

                                if (!topicPositionVal && 'isTopic' in el.dataset) {
                                    const topics = document.querySelectorAll('[data-is-topic]'),
                                        topicIndex = Array.prototype.indexOf.call(topics, el);

                                    if (topicIndex !== -1) {
                                        data.eventProps.topic_position = topicIndex;
                                        el.setAttribute('data-topic_position', topicIndex);
                                    }
                                } else {
                                    data.eventProps.topic_position = topicPositionVal;
                                }
                                break;
                            }
                            case 'entity_array_name': {
                                // If there is no already provided value, entity names will be aggregated from tile click tags
                                const entityArrayNameVal = getPropValue(el, 'entity_array_name');

                                if (!entityArrayNameVal) {
                                    const entities = el.querySelectorAll('[data-tile_name]');

                                    if (entities.length) {
                                        data.eventProps.entity_array_name = Array.prototype.map.bind(entities, entity => entity.dataset.tile_name)();
                                    }
                                } else {
                                    data.eventProps.entity_array_name = entityArrayNameVal;
                                }
                                break;
                            }
                            case 'entity_array_id': {
                                // If there is no already provided value, entity ids will be aggregated from tile click tags
                                // First we check is these are coupons, then fallback to stores
                                const entityArrayIdVal = getPropValue(el, 'entity_array_id');

                                if (!entityArrayIdVal) {
                                    const couponEntities = el.querySelectorAll('[data-coupon_id]'),
                                        entities = couponEntities.length ? couponEntities : el.querySelectorAll('[data-store_id]');

                                    if (entities.length) {
                                        const datapoint = couponEntities.length ? 'coupon_id' : 'store_id';

                                        data.eventProps.entity_array_id = Array.prototype.map.bind(entities, ent => ent.dataset[datapoint])();
                                    }
                                }  else {
                                    data.eventProps.entity_array_id = entityArrayIdVal;
                                }
                                break;
                            }
                            // populate remaining props with matching tag values from the dataset
                            default: {
                                const value = getPropValue(el, fieldName);

                                value !== null && (data.eventProps[fieldName] = value);
                            }
                            }
                            return data;
                        },
                        eventData
                    ) : eventData;
                },
                initModuleTileViewTracking = module => {
                    const viewsUseClickProps = 'viewsUseClickProps' in module.dataset,       // data-views-use-click-props
                        tiles = module.querySelectorAll(viewsUseClickProps ? '[data-evt="Click Tile"]' : '[data-view-evt]'),
                        scroller = module.querySelector('[data-is-scroller]'),
                        container = scroller || document.getElementById('scrolling-container') || document,
                        initTileViewTracking = tile => {
                            if (!tile.classList.contains('tile-init-view')){
                                const eventSignature = tile.dataset[viewsUseClickProps ? 'evtSig' : 'viewSig'],  // data-view-sig
                                    eventType = viewsUseClickProps ? 'View Tile' : tile.dataset.viewEvt, // data-view-evt
                                    trackTileView = () => {
                                        if (ebates.UI.isInView(tile)) {
                                            const viewEventData = getEventData(tile, eventType, eventSignature),
                                                viewEvent = new CustomEvent('stats-event', { detail: { map: { amp: viewEventData } } });

                                            tile.viewScrollListenerId && ebates.UI.scroll.off(tile.viewScrollListenerId);   // done - turn off the listener
                                            document.dispatchEvent(viewEvent);
                                        }
                                    };

                                // if tile is in view - fire view event, else init its scroll listener
                                ebates.UI.isInView(tile) ?
                                    trackTileView() :
                                    tile.viewScrollListenerId = ebates.UI.scroll.add(trackTileView, container, scrollThrottle);

                                tile.classList.add('tile-init-view');
                            }
                        };

                    tiles.length && setTimeout(Array.prototype.map.bind(tiles, initTileViewTracking), 0);

                    // fire one module scroll event
                    if (scroller && scroller.dataset.scrollEvt) {    // data-scroll-evt
                        const fireOneScrollEvent = function () {
                            const eventSignature = this.dataset.scrollSig,  // data-scroll-sig
                                eventType = this.dataset.scrollEvt,   // data-scroll-evt
                                viewEventData = getEventData(this, eventType, eventSignature),
                                viewEvent = new CustomEvent('stats-event', { detail: { map: { amp: viewEventData } } });

                            this.scrollEventListenerId && ebates.UI.scroll.off(this.scrollEventListenerId);   // done - turn off the listener
                            document.dispatchEvent(viewEvent);
                        };

                        scroller.scrollEventListenerId = ebates.UI.scroll.add(fireOneScrollEvent.bind(scroller), scroller);
                    }
                },
                initModuleViewTracking = module => {
                    if (!module.classList.contains('module-init-view')){
                        const container = document.getElementById('scrolling-container') || document,
                            eventSignature = module.dataset.viewSig,  // data-view-sig
                            eventType = module.dataset.viewEvt, // data-view-evt
                            /**
                             * @desc used to retrive data-view-fract; to be used for bigger than viewport modules. ex.: data-view-fract="0.05" - 5%
                             * @type {{fraction: number}}
                             */
                            viewOpts = module.dataset.viewFract ? { fraction: +module.dataset.viewFract } : {},
                            trackModuleView = () => {
                                if (ebates.UI.isInView(module, viewOpts)) {
                                    const viewEventData = getEventData(module, eventType, eventSignature),
                                        viewEvent = new CustomEvent('stats-event', { detail: { map: { amp: viewEventData } } });

                                    module.viewScrollListenerId && ebates.UI.scroll.off(module.viewScrollListenerId);   // done - turn off the listener
                                    document.dispatchEvent(viewEvent);
                                    initModuleTileViewTracking(module);
                                }
                            };

                        // if module is in view - fire view event, else init its scroll listener
                        ebates.UI.isInView(module, viewOpts) ?
                            trackModuleView() :
                            module.viewScrollListenerId = ebates.UI.scroll.add(trackModuleView, container, scrollThrottle);

                        module.classList.add('module-init-view');
                    }
                };

            modules.length && setTimeout(Array.prototype.map.bind(modules, initModuleViewTracking), 0);
        }

        /**
         * Legacy tagging; for backward compat; eventually to be deprecated and removed
         */
        {
            if (scrollerEl && !scrollerEl.classList.contains('scroller-initialized')) {
                const $doc = $(document),
                    oneScrollEventElements = scrollerEl.querySelectorAll('[data-send-one-scroll-event]'),
                    viewEventElements = scrollerEl.querySelectorAll('[data-send-view]'),
                    initialCap = str => str && str.replace(str[0], str[0].toUpperCase()),
                    eventDataFactory = {
                        template: {
                            data: ['send', 'event'],
                            map: {
                                external: {},
                                amp: { eventProps: {} }
                            }
                        },
                        populate: {
                            ga: function (eventClass, template) {
                                this.dataset[eventClass + 'Ga'].split(',').map(function (datum) {
                                    template.data.push(datum);
                                });
                            },
                            external: function (eventClass, template) {
                                this.dataset[eventClass + 'Signature'].split(',').map(fieldName => {
                                    template.map.external[fieldName] = this.dataset[eventClass + initialCap(fieldName)];
                                });
                            },
                            amp: function (eventClass, template) {
                                const signatureDataName = `${eventClass}AmpSignature`,
                                    ampEventSignature = typeof this.dataset[signatureDataName] !== undefined && this.dataset[signatureDataName];

                                template.map.amp.eventType = this.dataset[eventClass + 'AmpType'];
                                if (ampEventSignature) {
                                    const propNames = ampEventSignature.split(',');

                                    propNames.map(fieldName => {
                                        switch (fieldName) {
                                        // - translate '_eds' to the addEdsPropSet flag, which in turn will signal the SDK to add the EDS user data set to the event
                                        case '_eds':
                                            template.map.amp.addEdsPropSet = true;
                                            break;
                                            // populate remaining props with matching tag values from the dataset
                                        default:
                                            template.map.amp.eventProps[fieldName] = this.dataset[eventClass + initialCap(fieldName)];
                                        }
                                    });
                                }
                            }
                        }
                    },
                    fireOneScrollEvent = function () {
                        const thisDataset = this.dataset,
                            self = this,
                            eventDestinations = (thisDataset['sendOneScrollEvent'] || '').split(','),
                            eventData = JSON.parse(JSON.stringify(eventDataFactory.template));

                        // populate event data
                        eventDestinations.map(function (eventDestination) {
                            eventDataFactory.populate[eventDestination] && eventDataFactory.populate[eventDestination].bind(self)('scrollEvent', eventData);
                        });
                        // send the event
                        $doc.trigger('stats', eventData);
                        // done - turn off the listener
                        ebates.UI.scroll.off(this.oneScrollListenerId);
                    },
                    fireOneInViewEvent = function () {
                        if (ebates.UI.isInView(this) && this.viewEventData) {
                            ebates.UI.scroll.off(this.viewScrollListenerId);   // done - turn off the listener
                            $doc.trigger('stats', this.viewEventData);
                        }
                    },
                    addScrollListeners = scroller => {
                        if (!scroller.classList.contains('module-init-scroll')){
                            const scrollViewEventTargets = scroller.querySelectorAll('[data-scroll-view-event]'),
                                initScrollViewEventTarget = scrollViewEventTarget => {
                                    const eventDestinations = (scrollViewEventTarget.dataset['scrollViewEvent'] || '').split(','),  // "ga,amp" -> ['ga', 'amp']
                                        eventData = JSON.parse(JSON.stringify(eventDataFactory.template));  // clone event template

                                    // populate event data (eventData)
                                    eventDestinations.map(eventDestination => {
                                        eventDataFactory.populate[eventDestination] &&
                                        eventDataFactory.populate[eventDestination].bind(scrollViewEventTarget)('scrollViewEvent', eventData);
                                    });

                                    // attach event data to the element
                                    scrollViewEventTarget.viewEventData = eventData;

                                    // set scroll listener on the "scroller" (note: scroll event listener on the document is not set here yet!)
                                    scrollViewEventTarget.viewScrollListenerId = ebates.UI.scroll.add(fireOneInViewEvent.bind(scrollViewEventTarget), scroller);

                                    // check if the element is already visible and if so, fire the event
                                    fireOneInViewEvent.bind(scrollViewEventTarget)();
                                };

                            scrollViewEventTargets.length && Array.prototype.map.call(scrollViewEventTargets, initScrollViewEventTarget);
                            scroller.oneScrollListenerId = ebates.UI.scroll.add(fireOneScrollEvent.bind(scroller), scroller);

                            scroller.classList.add('module-init-scroll');
                        }
                    },
                    initViewEvents = module => {
                        if (!module.classList.contains('module-init-view')){
                            const eventData = JSON.parse(JSON.stringify(eventDataFactory.template)),
                                eventDestinations = (module.dataset['sendView'] || '').split(','),
                                sendViewEvent = () => {
                                    if (ebates.UI.isInView(module)) {
                                        module.viewScrollListenerId && ebates.UI.scroll.off(module.viewScrollListenerId);   // done - turn off the listener
                                        $doc.trigger('stats', eventData);
                                    }
                                };

                            eventDestinations.map(function (eventDestination) {
                                eventDataFactory.populate[eventDestination] && eventDataFactory.populate[eventDestination].bind(module)('view', eventData);
                            });

                            // if module is in view - fire view events, else init scroll listener
                            ebates.UI.isInView(module) ? sendViewEvent() : module.viewScrollListenerId = ebates.UI.scroll.add(sendViewEvent);

                            module.classList.add('module-init-view');
                        }
                    };

                // set event listeners on all scrollers; off main thread and to avoid firing scroll on pre-scrolled container
                oneScrollEventElements.length && setTimeout(Array.prototype.map.bind(oneScrollEventElements, addScrollListeners), 0);
                viewEventElements.length && setTimeout(Array.prototype.map.bind(viewEventElements, initViewEvents), 0);
            }
        }
    };

    ebates.stats.taggedScrollerInit = id => {
        const scrollerEl = document.getElementById(id);

        ebates.stats.taggedModuleInit(scrollerEl);
    };

    // De-jQuery-ified (but otherwise identical to the original implementation) Ad Blocker detection module
    // The only external dependency is 'blockdecoy' classname related CSS rules

    const defaultOpts = {
        adBlockerStateProp: 'adBlockerActive',
        adBlockerDetectEventName: 'ad-blocker-detected',
        adBlockerCheckTimeout: 650  // heuristically derived value (c. 2015); some blockers take their lazy time to remove ads
    };

    var adBlockerChecker = (state, {
        adBlockerStateProp = defaultOpts.adBlockerStateProp,
        adBlockerDetectEventName = defaultOpts.adBlockerDetectEventName,
        adBlockerCheckTimeout = defaultOpts.adBlockerCheckTimeout
    } = defaultOpts ) => {
        const adDecoyEl = document.createElement('div'),
            // https://github.com/jquery/jquery/blob/d0ce00cdfa680f1f0c38460bc51ea14079ae8b07/src/css/hiddenVisibleSelectors.js
            // this simple "visibility" method has worked extremely reliably (95% + accuracy) for years, for any DOM-aware ad blocker detection
            isNotVisible = el => !(el.offsetWidth || el.offsetHeight || el.getClientRects().length),
            checkForAdBlockerPresence = decoy => {
                if (isNotVisible(decoy)) {
                    document.dispatchEvent(new Event(adBlockerDetectEventName));
                    state[adBlockerStateProp] = true;
                }
                document.body.removeChild(adDecoyEl);
            };

        adDecoyEl.classList.add('blockdecoy');
        adDecoyEl.classList.add('google-ads');
        adDecoyEl.innerHTML = '&nbsp;';
        document.body.appendChild(adDecoyEl);
        state[adBlockerStateProp] = false;
        setTimeout(checkForAdBlockerPresence.bind(null, adDecoyEl), adBlockerCheckTimeout);
    };

    //--- Tagged Scroller for events to fire only when on viewport ---//

    /* --- Sticky Module --- */
    ebates.stickyModule = function(el, bottom, aboveEl, fixedTop, fixedTopMargin){
        var elHeight = el.outerHeight();
        $(window).on('scroll-throttle', function(){
            var bottomPosition = bottom.offset().top - elHeight,
                topHeight = fixedTop.outerHeight() + fixedTopMargin,
                scrollTop = $(window).scrollTop(),
                topOffset = aboveEl.offset().top + aboveEl.height(),
                isInPlace = topOffset > scrollTop;
            if (bottomPosition < scrollTop + topHeight) { // the element reached bottom
                !el.hasClass('reached-bottom') && el.css({position: 'absolute', top: bottomPosition}).addClass('reached-bottom').removeClass('is-in-place reached-top');
                return;
            }
            if (isInPlace && !el.hasClass('is-in-place')) {
                el.css({position: 'absolute', top: topOffset}).removeClass('reached-bottom reached-top');
            }
            if (el.offset().top <= scrollTop + topHeight || el.hasClass('reached-bottom')) { // the element hit ceiling
                !el.hasClass('reached-top') && el.css({position: 'fixed', top: topHeight}).addClass('reached-top').removeClass('reached-bottom is-in-place');
            }
        });
    };

    top.location !== location && top.location.hostname !== 'app.optimizely.com' && (top.location.href = document.location.href);

    ebates.button = ebates.button || {};
    ebates.button.qualifies = function(){
        var buttonLs = JSON.parse(localStorage.getItem('ebates-button')),
            isInstalled = buttonLs && buttonLs.installed && buttonLs.ttl > +Date.now(),
            qualifies = !isInstalled,
            weekInMs = 604800000;
        if (qualifies) {
            qualifies = ebates.button.browser !== 'unknown' && !ebates.button.isButtonInstalled();
            if (!qualifies) {
                try {
                    localStorage.setItem('ebates-button', JSON.stringify({ installed: true, ttl: +Date.now() + weekInMs }));
                } catch (e) { }
            }
        }
        return qualifies;
    };
    // --- END Legacy globals --- //

    // Lets use state
    // just please remember to put horse in the stables, bed in the bedroom and fridge in the kitchen...
    ebates.state = ebates.state || {};

    // --- on DOM ready --- //
    $(function(){
        const adBlockerDetectEventName = 'ad-blocker-detected',
            body = $('body'),
            $doc = $(document),
            loggedIn = ebates && ebates.user && ebates.user.loggedIn;

        // --- Global AdBlock Detection --- //
        adBlockerChecker(ebates.state, { adBlockerDetectEventName });
        // --- AdBlock Detection warning widget - to not use it on a page add "no-abp" class to any element
        if (loggedIn && !$('.no-abp').length) {
            document.addEventListener(adBlockerDetectEventName, () => ebates.js.require(ebates.src.abp));
        }

        // ---  Sign-up email auto-correction - add "auto-correct" class to any email field to auto-correct user input --- //
        body.one('input', '.auto-correct', function(){
            ebates.js.require(ebates.src.emailCorrector.loader, function(){
                ebates.loadMailCheck('.auto-correct');
            });
        });

        // --- Button sticky-footer --- //
        if (loggedIn && !document.querySelector('.no-button-sf')) {
            setTimeout(function(){  // allow enough time for the toolbar to do its snippet injection
                var rightBrowser = function(){
                        return $.browser.webkit || $.browser.mozilla && !ebates.regex.IE.test(navigator.userAgent);
                    },
                    sfEl = document.querySelector('.footer-fixed');
                if (!ebates.button.isButtonInstalled() && sfEl && rightBrowser() &&
                    (sfEl.querySelector('.sf-content').innerHTML.trim() === '' || ebates.user.status === 'REGISTERED_SHOPPER_NON_BUYER')) {
                    $.ajax('/ajax/sf/button.htm', { cache: true }).done(function(r){
                        sfEl.classList.add('browser-extension');
                        sfEl.classList.add('bg-gry-f6');
                        sfEl.classList.remove('bg-w');//If sf replaced by button sf, remove the previous addec classes for the pending sf
                        $('.sf-content', sfEl).html(r);
                        ebates.js.require(ebates.src.sf, function(){
                            ebates.sf.init(2);
                        });
                        ebates.js.require(ebates.src.button, function(){
                            function sendStickyAmpEvent() {
                                document.dispatchEvent(new CustomEvent('stats-event', {detail: {map: {amp: {
                                    eventType: 'View Module',
                                    eventProps: { module_type: 'Sticky Footer', module_name: 'Button' }
                                }}}}));
                            }
                            ebates.button.UI.init({
                                el: document.querySelector('.button-sf'), name: 'Footer', utm: 'sticky_footer', src: 'external-sticky_footer', callback: sendStickyAmpEvent
                            });
                        });
                    }).fail(function(){
                        $doc.trigger('sf-ready');
                    });
                    $doc.trigger('button-sf');
                } else {
                    $doc.trigger('sf-ready');
                }
            }, 1200);
        } else {
            $doc.trigger('sf-ready');
        }

        // --- Left nav lazy load --- //

        $('#main-nav').on('mouseover click', '.main-cat-link', function(){
            var images,
                th = $(this);
            if (th.hasClass('img-loaded')){
                return;
            }
            images = $('.main-cat-sub-image', $(this).parent()).find('img').fadeOut(0);
            images.each(function(){
                $(this).attr('src', $(this).attr('data-href'));
            }).fadeIn(300, function(){
                th.addClass('img-loaded');
            });
        });
        if (document.body.classList.contains('ios') && document.body.classList.contains('is-tablet')){
            $('#main-nav').on('click', function () {
                var images,
                    th = $(this);
                if (th.hasClass('img-loaded')){
                    return;
                }
                images = $('.main-cat-sub-image', $(this).parent()).find('img').fadeOut(0);
                images.each(function(){
                    $(this).attr('src', $(this).attr('data-href'));
                }).fadeIn(300, function(){
                    th.addClass('img-loaded');
                });
            });
        }

        // --- back to mobile site from mobile-desktop --- //
        $('.back-to-mobile-btn').on('click', function(){
            $.post('/mobile_web/ajax/ajax_session_mobiledesktop.vm?set=mobile').done(function (r){
                r.trim().toLowerCase() === 'success' && window.location.reload();
            });
            return false;
        });

        // --- Global Header Google + --- //
        /*global browserUrlInfo */
        body.on('click', '.gplus-btn, .skinny-gplus-btn', function(){
            var promoInput = $('input[name=\'ebates.signup.promo\']');
            var signupBonus = promoInput.is(':visible') && ($('.v03').length && promoInput.val() || $('input[name=\'ebates.signup.promo\']:checked', '#signForm, #join-signup-form').val());
            var typeInput = $('input[name=\'type\']').not('#frmStoreSearch2 input[name=\'type\']'),
                typeParam = typeInput.length && '&type=' + typeInput.val() || '',
                splitId = $('#signForm, #signUpForm').is(':visible') && $('input[name=\'split_entry_id\']').val() || '',
                signupBonusParam = signupBonus && '&ebates.signup.promo=' + signupBonus || '',
                splitIdParam = splitId && '&split_entry_id=' + splitId || '',
                url = '/googleOAuth2.htm?callback=onSignInCallback&urlIdentifier=' + encodeURIComponent(encodeURIComponent(browserUrlInfo)) + signupBonusParam + splitIdParam + typeParam;
            if (!signupBonus){
                if ($(this).hasClass('skinny-gplus-btn')){
                    signupBonus = 'SUJOIN10-201503';
                } else {
                    signupBonus = 'SU5A-201210';
                }
            }
            window.open(url, 'popUpWindow', 'height=500,width=600,left=200,top=100,resizable=yes,scrollbars=yes');
            return false;
        })
            .on('click', '.gconnect-btn', function(e){
                e.preventDefault();
                window.open('/googleOAuth2.htm?callback=onConnectCallback', 'popUpWindow', 'height=500,width=600,left=400,top=100,resizable=yes,scrollbars=yes');
                return;
            });

        // --- NM related - remove nm_api cookie on logout --- //
        $('.sign-out').on('click', function(e){
            e.preventDefault();
            ebates.cookie.set('nm_api', 1, -1); document.location = $(this).attr('href'); return;
        });

        // --- All Stores homepage - remove when old all-stores section is no longer used --- //
        /*global  postShopOverlay*/
        (function(){
            var inp = $('input[name=\'all-stores\']'),
                allStoresMenu = $('.merch-menu'),
                allStoresUrl = location.hostname + (location.port ? ':' + location.port : '') + '/ajax/all-stores.htm';
            allStoresMenu.one('click', 'form', function(){
                $(this).addClass('loading');
                ebates.js.require(ebates.src.suggest.stores, function(){
                    var t = 0,
                        click = function(){
                            var exceptions = {10634: '/groupon.htm', 3993: '/ebay', 11445: '/livingsocial.htm'},
                                id = $(this).data('id'),
                                generalURL = '/mall_ctrl.do?merchant_id=' + id + '&sourceName=Web-Desktop',
                                amazon = '/Amazon.com_1-xfas?mpl_id=38378&sourceName=Web-Desktop',
                                url = ebates && ebates.user && ebates.user.loggedIn ? (id === '1' ? amazon : generalURL) : (exceptions[id] || generalURL);
                            $(document).trigger('set-nav-id', [ ebates.stats.parentNavId + '|' + inp.attr('data-navigation-id') ]);
                            window.open(url, id).focus();
                            ebates && ebates.user && ebates.user.loggedIn && typeof postShopOverlay === 'function' && postShopOverlay(id);
                        };
                    ebates.suggest({input: inp, url: allStoresUrl, classes: 'all-stores ac_results', justLoadAll: true, click: $.noop});
                    allStoresMenu.on('mouseleave', '.all-stores.ac_results', function(){
                        t = setTimeout(function(){
                            inp.trigger('blur');
                        }, 3000);
                    })
                        .on('mouseover', '.all-stores.ac_results', function(){
                            clearTimeout(t);
                        });
                    inp.trigger('input').on('s-loaded', function(){
                        $('#frmStoresList').find('.suggestion').on('click', click);
                    });
                });
            });
            $('#frmStoresList').on('submit', function(){
                return false;
            });
        })();

        //-- Browser Detection  TODO - needs some further work --//
        // TODO: move to ebates-main.js since same function defined there. //
        /*global pheader */
        $(function() {
            var ua, browserName, browserVersion, tem, M;
            var browserSymbol, flag, updateUrl, bInfo;
            if (! /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                ua = navigator.userAgent;
                M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

                if (/trident/i.test(M[1])){
                    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                    if (tem[1] === '11'){
                        $('div.browserInfo').hide();
                    }
                    return {name: 'IE', version: (tem[1] || '')};
                }
                if (M[1] === 'Chrome'){
                    tem = ua.match(/\bOPR\/(\d+)/);
                    if (tem != null) {
                        return {name: 'Opera', version: tem[1]};
                    }
                }

                M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
                if ((tem = ua.match(/version\/(\d+)/i)) != null) {
                    M.splice(1, 1, tem[1]);
                }

                browserName = M[0];	browserVersion = M[1];

                if (browserName === 'Firefox' && browserVersion <= 14){
                    flag = true;browserSymbol = 'fa-firefox';
                    updateUrl = 'https://www.mozilla.org/en-US/firefox/new/';
                } else if (browserName === 'Chrome' && browserVersion <= 20){
                    flag = true;browserSymbol = 'fa-chrome';
                    updateUrl = 'http://www.google.com/chrome/';
                } else if (browserName === 'MSIE' && browserVersion <= 10){
                    browserName = 'Internet Explorer';
                    flag = true;browserSymbol = 'fa-internet-explorer';
                    updateUrl = 'https://support.microsoft.com/en-us/help/17621/internet-explorer-downloads';
                } else if (browserName === 'Safari' && browserVersion <= 5){
                    flag = true; browserSymbol = 'fa-safari';
                    updateUrl = 'https://support.apple.com/downloads/safari';
                } else {
                    flag = false;
                }

                if (flag){
                    $('div#oldBrowser').show();
                    bInfo = '<div class=\'container-fluid h-browser' + pheader + ' bg-yel trans-bg ht-40 browserInfo\'><div class=\'container-fixed\'><div class=\'blk0\'><div class=\'blk-group\'><div class=\'blk col3 col_2_3 ht-40\'><p class=\'blk prox-b f-15 lh-40 mar-0 pad-20-l w-685px\'>Please upgrade to the latest version of your browser to experience all the perks Rakuten has to offer.</p></div><div class=\'blk col3 ht-40 col-last\'><a id=\'browser-anchor\' href=\'' + updateUrl + '\' class=\'h-browser-type lh-40  pad-40-l f-gry-3 bblk nohover specific\' target=\'_blank\'><span class=\'pad-10-t h-icon fa f-18 ' + browserSymbol + ' f-17 mar-5-r bblk flt f-gry-3\'></span> <span class=\'bblk flt prox-b\'> Upgrade ' + browserName + ' Now</span> <span class=\'pad-10-t fa-chevron-circle-right f-13 mar-5-l f-gry-3 bblk flt\'></span></a></div></div></div></div></div>';
                    $('div#oldBrowser').append(bInfo);
                } else {
                    $('div#oldBrowser').remove();
                }
            }
        });

        // --- Global overlay loader --- //
        (function(){
            var legacyOverlayMap = {
                    '/ajax/signin.htm': 'si',
                    '/ajax/join.htm': 'su',
                    '/ajax/join2.htm': 'hsu',
                    '/ajax/join6.htm': 'lsu',
                    '/ajax/join10.htm': 'fsu',
                    '/ajax/hotels/su.htm': 'hotelsSu',
                    '/ajax/gsw-signin.htm': 'gswSi',
                    '/ajax/si-revamp.htm': 'siRevamp',
                    '/ajax/su-revamp.htm': 'suRevamp',
                    '/ajax/auth-ms.htm': 'authMsSu',
                    '/ajax/auth-ms.htm?authMode=login': 'authMsSi'
                },
                legacyOverlayUris = {
                    si: '/ajax/signin.htm',
                    su: '/ajax/join.htm',
                    hsu: '/ajax/join2.htm',
                    lsu: '/ajax/join6.htm',
                    fsu: '/ajax/join10.htm',
                    hotelsSu: '/ajax/hotels/su.htm',
                    gswSi: '/ajax/gsw-signin.htm',
                    siRevamp: '/ajax/si-revamp.htm',
                    suRevamp: '/ajax/su-revamp.htm',
                    authMsSu: '/ajax/auth-ms.htm',
                    authMsSi: '/ajax/auth-ms.htm?authMode=login'
                },
                skinnyData = {
                    su: {url: '/ajax/join.htm', modalName: 'skinny-su-modal', css: ebates.src.css.skinny},
                    hsu: {url: '/ajax/join2.htm', modalName: 'skinny-su-modal', css: ebates.src.css.skinny},
                    lsu: {url: '/ajax/join6.htm', modalName: 'skinny-su-modal', css: ebates.src.css.skinny},
                    fsu: {url: '/ajax/join10.htm', modalName: 'skinny-su-modal', css: ebates.src.css.skinny},
                    si: {url: '/ajax/signin.htm', modalName: 'skinny-si-modal', css: ebates.src.css.skinny},
                    suRevamp: {url: '/ajax/su-revamp.htm', modalName: 'skinny-su-modal', css: ebates.src.css.skinny},
                    siRevamp: {url: '/ajax/si-revamp.htm', modalName: 'skinny-si-modal', css: ebates.src.css.skinny},
                    hotelsSu: {url: '/ajax/hotels/su.htm', modalName: 'hotels-su-modal', css: ebates.src.css.hotelsSu},
                    gswSi: {url: '/ajax/gsw-signin.htm', modalName: 'gsw-si-modal', css: ebates.src.css.gswSi},
                    authMsSu: {url: legacyOverlayUris.authMsSu, modalName: 'skinny-su-modal', css: ebates.src.css.skinny},
                    authMsSi: {url: legacyOverlayUris.authMsSi, modalName: 'skinny-su-modal', css: ebates.src.css.skinny}
                },
                delay = 200,
                getSuSiOverlayOpts = function(type){
                    var link = this,
                        reEnableLink = link && link.classList ? function(){
                            setTimeout(function(){
                                link.classList.remove('disabled');
                            }, delay);
                        } : function(){};
                    return [
                        '<div class="pad-30 center">Loading...</div>',
                        {
                            url: skinnyData[type].url,
                            css: { files: [ebates.src.css.modal, skinnyData[type].css] },
                            classes: { toAdd: ['trans-opacity', skinnyData[type].modalName, 'op0', 'border-grn-t-3', 'w-400'] }
                        },
                        { ajax: { done: reEnableLink, error: reEnableLink } }
                    ];
                };
            ebates.overlays = ebates.overlays || {};    // global overlay handling object
            ebates.overlays.load = function(options){
                ebates.js.require(ebates.src.modal, function() {
                    ebates.UI.modal.apply(ebates.UI, options);
                });
            };
            $doc.on('load-overlay', function(e, d){
                ebates.overlays.load(d);
            });
            // Load the winning overlay, contained in ebates.overlays.activate
            // Example overlay candidate activation:
            // var overlayPriority = 9;
            // ebates.overlays = ebates.overlays || {};
            // ebates.overlays.activate = (!ebates.overlays.activate || ebates.overlays.activate.priority < overlayPriority) &&
            //    {
            //         options:    ["Loading...", {url: "/ajax/skinny-coupons-su.htm"}],
            //         priority:   overlayPriority
            //    };
            setTimeout(function(){
                if ( !ebates.disableOverlay && ebates.overlays.activate && ebates.overlays.activate.options && !(ebates.UI && ebates.UI.active && ebates.UI.active.modal) ) {
                    ebates.overlays.load(ebates.overlays.activate.options);
                }
            }, 0);

            // Legacy Skinny overlay loader - remove when no longer used
            ebates.overlays.skinnyLoader = function(){
                var showOverlay, targetPage,
                    ths = this;
                showOverlay = function(res){
                    var r = $(res),
                        track = ths.getAttribute('data-track'),
                        tracker = track && r.find('input[name=\'urlIdentifier\']');
                    tracker && tracker.length && tracker.val(track);
                    body.append(r);
                    setTimeout(function(){
                        $(document).trigger('overlay-loaded');
                    }, 0);
                };
                targetPage = ths.getAttribute('data-redirect-url') || ths.getAttribute('data-target-url') || window.location.pathname + window.location.search;
                $.ajax({type: 'get', url: this.getAttribute('data-href'), data: {p: targetPage} })
                    .done(showOverlay)
                    .always(function(){
                        ths.classList.remove('disabled');
                    });
            };
            // New default SU/SI overlay loader
            ebates.overlays.suSiLoader = function(){
                var link = this,
                    dataHref = link.getAttribute('data-href'),
                    targetUri = link.getAttribute('data-redirect-url') || link.getAttribute('data-target-url') || location.pathname + location.search;
                ebates.js.require(ebates.src.modal, function() {
                    var options = getSuSiOverlayOpts.bind(link)(legacyOverlayMap[dataHref]);
                    options[1].url = ebates.param.add('p', encodeURIComponent(targetUri), options[1].url);
                    ebates.UI.modal.apply(ebates.UI, options);
                });
            };
            // SU/SI link handler  - legacy compatible. See legacyOverlayMap above for which overlays will be loaded via "new" way
            body.on('click', '.show-join-overlay', function(e){
                var dataHref = this.getAttribute('data-href');
                e.preventDefault();
                if (this.classList.contains('disabled')) {
                    return;
                }
                this.classList.add('disabled');
                $('#join-signup, #signupOverlay, #overlay, #backgroundPopup').remove();
                ((dataHref && legacyOverlayMap[dataHref]) ? ebates.overlays.suSiLoader : ebates.overlays.skinnyLoader).bind(this)();
                try {
                    window.localStorage.setItem('auth_source', this.getAttribute('data-auth-source') || 'other');
                } catch (e){}
                return;
            });

            body.on('click', '.forgot-pwd-modal-link', function(){
                $('a#disable').trigger('click');
                ebates.css.require(ebates.src.css.accountRecovery);
                ebates.js.require(ebates.src.accountRecovery, function() {
                    $(document).trigger('loadForgotPasswordModal');
                });
                $doc.trigger('stats', {
                    data: ['send', 'event', 'Account Recovery', 'Click - Forgot Password Link', 'Forgot Password - Open email modal'],
                    map: {external: {ec: 'Account Recovery', ea: 'Click - Forgot Password Link', el: 'Forgot Password - Open email modal'}}
                });
            });

            /*
             *   Description : Loads the general (no store branding) "get the app" overlay by listening to a click event on an element with a "show-get-app-overlay" class.
             *                 If this was triggered from a coupon/promo click, we set the merchantId and coupon/special ID in the uri to be used
             *                 in the overlay to create the right branch link.
             *   Example     : <a data-href="$special.getLink()&sourceName=Web-Desktop$!splitParam"
             *                    class="show-get-app-overlay"
             *                    target="_blank"
             *                    data-merchant-id="$merchant.id">Click Me</a>
             *
            */
            body.on('click', '.show-get-app-overlay', function(e) {
                ebates.js.require(ebates.src.modal, function () {
                    const target = e.target;
                    const href = target.getAttribute('data-href') || '';
                    const merchantId = target.getAttribute('data-merchant-id') || '';
                    const isStoreSpecific = target.getAttribute('data-is-store-specific') || '';
                    const isRakutenMode = target.getAttribute('data-is-rakutenmode') || '';
                    // specialID relating to coupon/special clicked
                    const matched = href && href.match(ebates.regex.specialId);
                    let overlayUri = '/ajax/get-the-app.htm';
                    let specialId = '';

                    if (matched) {
                        specialId = matched[1];
                    }

                    overlayUri = merchantId ? ebates.param.add('merchantId', merchantId, overlayUri) : overlayUri;
                    overlayUri = specialId ? ebates.param.add('specialId', specialId, overlayUri) : overlayUri;
                    overlayUri = isStoreSpecific ? ebates.param.add('isStoreSpecific', isStoreSpecific, overlayUri) : overlayUri;
                    overlayUri = isRakutenMode ? ebates.param.add('isRakutenMode', isRakutenMode, overlayUri) : overlayUri;

                    ebates.UI.modal(
                        null,
                        {
                            url: overlayUri,
                            css: {
                                files: [ebates.src.css.modal, '#macro_version_asset(\'/static/rev/overlays/get_the_app/get-the-app.css\')']   // FIXME
                            },
                            classes: {
                                toAdd: ['trans-opacity', 'w-500', 'border-grn-t-3', 'op0', 'absolute']
                            },
                            bg: { classes: ['trans-opacity', 'op6'], closeOnClick: false },
                            closing: {x: true}
                        },
                        {
                            ajax: {
                                done: () => {
                                    if (!isStoreSpecific) {
                                        $('.eb-modal-close').css({color: '#FFFFFF'});
                                    }
                                }
                            }
                        }
                    );
                });
            });
            // custom event to load SU/SI overlays. Ex.: $("body").trigger("load-susi-overlay");
            $doc.on('load-susi-overlay', function(e, data, link){
                var linkEl = link || document.createElement('a'), // construct a dummy link to mimic actual link, so that one handler would work the same
                    authType = data && data['auth-type'],
                    autoTypeFromCookie = 'su';//Default to SU form since su could handle both su /si.
                if (ebates && ebates.user && ['FIRST_VISIT', 'UNREGISTERED'].indexOf(ebates.user.status) === -1 ){
                    autoTypeFromCookie = 'si';
                }
                authType = authType && legacyOverlayUris[authType] || legacyOverlayUris[autoTypeFromCookie];
                linkEl.setAttribute('data-href', authType);
                data && data['redirect-url'] && linkEl.setAttribute('data-redirect-url', data['redirect-url']);
                ebates.overlays.suSiLoader.apply(linkEl);
            });
            // handle links pointing to pages requiring authentication
            body.on('click', '.auth-required', function(e) {
                if (!(ebates && ebates.user && ebates.user.loggedIn)) {
                    e && e.preventDefault();
                    if (!this.classList.contains('disabled')) {
                        this.classList.add('disabled');
                        $doc.trigger('load-susi-overlay', [{ 'auth-type': this.getAttribute('data-auth-type'), 'redirect-url': this.getAttribute('href') }, this]);
                    }
                }
            });
        })();

        ebates.stats.taggedScrollerInit('container');
    });

    // Add event listener for View module amplitude events after module loaded via ajax
    document.addEventListener('ajax-module-loaded', e => ebates.stats.taggedModuleInit(e.detail.el));

})();
